// change the theme
$theme-colors: (
  "primary": #231f20,
  "secondary": #e93721,
  "danger": #f3040a,
  "light": #d4dbe0,
  "dark": #020001,
  "success": #0ca057,
  "warning": #e4c90c,
  "info": #fc4039,
);
$input-focus-border-color: rgb(100, 204, 197);
$input-focus-box-shadow: 0 0 0 0.2rem rgba(100, 204, 197, 0.25);
$breadcrumb-bg: rgba(26, 57, 80, 0.3);
$breadcrumb-border-radius: 0.375rem;
$breadcrumb-padding-x: 10px;
$breadcrumb-padding-y: 10px;
$breadcrumb-divider-color: #231f20;
// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

@font-face {
  font-family: "NotoSansLao";
  src: local("NotoSansLao"),
    url(./fonts/NotoSansLao/NotoSansLaoUI-Regular.ttf) format("truetype");
}
*,
body,
.Dialogbox,
label,
div {
  margin: 0;
  box-sizing: border-box;
  font-family: "NotoSansLao";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "NotoSansLao", Arial, Helvetica, sans-serif;
  /* font-size: 15px; */
}
body {
  width: 100%;
  overflow: hidden;
  height: 100dvh;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  color: #231f20;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
